import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          MoVA has been deprecated
        </h1>
        <h5>
          For Passive Monitoring related issues please raise ticket to Exchange/M365 Passive Monitoring team on <a href="https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=3Lh1l1" target="_blank" className="App-link">ICM</a>
        </h5>
      </header>
    </div>
  );
}

export default App;
